.recording-indicator {
  display: flex;
  align-items: center;
  gap: 8px;
  color: red;
  font-weight: bold;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  animation: blink 1s infinite ease-in-out;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}